import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import { Dialog } from '@front-office/app-notifications';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Dialog,
  errorBoundary(error) {
    console.error('Dialog load Error', error);

    return null;
  }
});

export const { bootstrap, mount, unmount } = lifecycles;
